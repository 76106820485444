import axios, { AxiosResponse } from "axios";
import { ResidentialResponse } from "../types/IdentificationApi/ResidentialResponse";
import { ResidentialTokenResponse } from "../types/IdentificationApi/ResidentialTokenResponse";
import { mockedData } from "../mocks/ResidentialInfo";

class ResidentialInfoService {
  private readonly NAME_APPLICATION = "assine";
  private readonly API_URL = `${process.env.identificationApiUrl}`;
  private readonly TOKEN_GENERATE_ENDPOINT = "/credentials/token/generate";
  private readonly RESIDENTIAL_INFO_ENDPOINT = "/customer/residential/info";
  private readonly HEADER_OBJ = {'headers':{
    'Content-Type': 'application/json',
    'x-channel': 'ecommerce',
    'x-segment': 'pf',
    'x-partner': '',
    'x-customer-journey': 'prospect'
  }}

  // Defining a Map to store previously retrieved residential
  // information for a given CPF.
  private readonly cpfResults: Map<string, ResidentialResponse> = new Map();

  constructor() { return }

  /**
   * This method is used to consult the residential information
   * of a customer by their CPF.
   * @param cpf The CPF of the customer to be consulted.
   */
  public async consultarCpf(cpf: string): Promise<ResidentialResponse> {
    return new Promise<ResidentialResponse>((resolve, reject) => {
      if (cpf in mockedData) {
        return resolve(mockedData[cpf]);
      }

      // Checking if the residential information for the given CPF
      // has already been retrieved and stored in the Map.
      if (this.cpfResults.has(cpf)) {
        const result = this.cpfResults.get(cpf);
        if (result) {
          resolve(result);
        }
      }

      // If the residential information has not been retrieved yet,
      // generate a token and make a request to retrieve the information.
      this.generateToken()
        .then(async (token) => {
          try {
            const tokenObj = { document: cpf.replace(/\D/g, ''), token };
            const response: AxiosResponse<ResidentialResponse> = await axios.post(
              `${this.API_URL}${this.RESIDENTIAL_INFO_ENDPOINT}`,
              tokenObj, this.HEADER_OBJ
            );

            // Storing the retrieved residential information in the Map
            // for future use.
            const residentialInfo = response.data;
            this.cpfResults.set(cpf, residentialInfo);

            resolve(residentialInfo);
          } catch (error) {
            reject(error);
          }
        })
        .catch(error => reject(error));
    });
  }

  /**
   * Generates a token for authentication purposes.
   * @returns A Promise that resolves to a string representing
   * the generated token.
   */
  private async generateToken(): Promise<string> {
    // Generating a timestamp to be used in the token generation.
    const today = new Date();
    const withOffset = today.getTime();
    const obj = { sessionId: '' };

    // Creating a session ID by encoding the application name and timestamp in Base64.
    obj.sessionId = window.btoa(this.NAME_APPLICATION + '.' + withOffset);
    console.log("generateTokents", `${this.API_URL}${this.TOKEN_GENERATE_ENDPOINT}`)
 
    const response: AxiosResponse<ResidentialTokenResponse> = await axios.post(
      `${this.API_URL}${this.TOKEN_GENERATE_ENDPOINT}`,
      obj
    );
    return response.data.data.token;
  }
}

export default new ResidentialInfoService();
